<script setup lang="ts">
// icons
import { faPen } from '@fortawesome/pro-regular-svg-icons';

// components
import CiSkeletonItem from '@/components/skeleton/CiSkeletonItem.vue';

/********************
 * REFS & VARS       *
 ********************/
export interface CiProfileImageProps {
  showName?: boolean;
}
const props = withDefaults(defineProps<CiProfileImageProps>(), {
  showName: true,
});

/********************
 * COMPOSITIONS      *
 ********************/
const backendStore = useBackendStore();
const userStore = useUserStore();
const { openModal, closeModal } = useCiModal();

/********************
 * REFS & VARS       *
 ********************/
const userImage = computed(() => {
  if (!userStore.user.profile?.images) {
    return '';
  }

  const img = userStore.user.profile.images.medium || userStore.user.profile.images.small;
  return fixImageUrl(backendStore.url, img);
});

/********************
 * FUNCTIONS         *
 ********************/
function editUserImage() {
  const CiProfileImageUploadModal = defineAsyncComponent({
    loader: () => import('@/components/account/profile/CiProfileImageUploadModal/CiProfileImageUploadModal.vue'),
  });

  openModal(
    {
      component: CiProfileImageUploadModal,
      attrs: {
        name: 'CiProfileImageUploadModal',
        onClose: () => {
          closeModal('CiProfileImageUploadModal');
        },
      },
    },
    {
      clickToClose: false,
      contentTransition: 'translate-y-down',
      contentClass: 'max-w-[800px] w-full h-auto',
    },
  );
}
</script>

<template>
  <client-only>
    <div v-if="userStore.user.profile">
      <div class="relative mx-auto w-[150px] max-w-full pt-[150px]">
        <img
          v-if="userStore.user.profile?.images?.small || userStore.user.profile?.images?.medium"
          :src="userImage"
          class="absolute top-0 h-auto w-full rounded-full"
        >
        <img
          v-else
          class="absolute top-0 h-auto w-full rounded-full"
          src="~/assets/svg/user_placeholder.grey.svg"
          alt=""
        >
        <button
          class="button button--primary button--circle absolute"
          style="bottom: 0; right: 0"
          @click="editUserImage()"
        >
          <CiAwesomeIcon
            :icon="faPen"
            ratio="0.75"
          />
        </button>
      </div>
      <div
        v-if="props.showName"
        class="mt-4 text-center font-medium"
      >
        {{ userStore.user.profile?.first_name }} {{ userStore.user.profile?.last_name }}
      </div>
    </div>
    <div v-else>
      <CiSkeletonItem class="mx-auto block size-[150px] rounded-full" />
      <CiSkeletonItem class="mx-auto mt-2 block h-[30px] w-[90%]" />
    </div>
    <template #fallback>
      <CiSkeletonItem class="mx-auto block size-[150px] rounded-full" />
      <CiSkeletonItem class="mx-auto mt-2 block h-[30px] w-[90%]" />
    </template>
  </client-only>
</template>

<style></style>
